// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-forgotten-js": () => import("./../../../src/pages/forgotten.js" /* webpackChunkName: "component---src-pages-forgotten-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-okr-js": () => import("./../../../src/pages/okr.js" /* webpackChunkName: "component---src-pages-okr-js" */),
  "component---src-pages-quotes-js": () => import("./../../../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-uat-js": () => import("./../../../src/pages/uat.js" /* webpackChunkName: "component---src-pages-uat-js" */),
  "component---src-pages-uxreview-js": () => import("./../../../src/pages/uxreview.js" /* webpackChunkName: "component---src-pages-uxreview-js" */)
}

